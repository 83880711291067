import { Component, OnInit, ElementRef, ViewChild, Input, Output, EventEmitter, ViewChildren, OnChanges } from '@angular/core';
import { AfterViewChecked } from '@angular/core';
import { HttpClient, HttpResponse, HttpEventType, HttpHeaderResponse, HttpErrorResponse } from '@angular/common/http';
import{ GetURL } from '../../service/gettingUrl.service';
import { SingleService } from '../../service/singleSearch.service';
import { DataService } from "../../service/shared.service";
import {Observable} from "rxjs";
import { saveAs } from 'file-saver';
import { SpinnerService } from '../../service/spinner.service';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { NavigateService } from '../../service/navigate.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})


export class TableComponent implements OnInit {
  message: string;
  @Input() tableData;
  @Input() searchInputList = [];
  @Input() flag = false;
  @Input() dropdown = [];
  private exportFile = {};
  private cableType = [];
  private formFactor = [];
  private transceiverFamily = [];
  private transceiverModel = [];
  private reach = [];
  private dataRate = [];
  private softwareType = [];
  private notes = [];

  imageError;
  productImage;
  fileResponse= [];
  fileCount= 0;
  fileName = null;
  selectedFiles: FileList;
  currentFile: File;
  page: number;
  searchResults: any;

  statusCreateForm: FormGroup;
  fileDescription: FormControl;
  fileToUpload: File  = null;
  uploadProgress:number = 0;
  uploadComplete:boolean = false;
  uploadingProgressing:boolean = false;
  fileUploadSub: any;
  serverResponse: any;
  @ViewChild('fileInput') myInputVariable: any;
  @Output() ResultsChanged = new EventEmitter();
  @Output() InputChanged = new EventEmitter();
  @Output() show = new EventEmitter();
  d: any;
  v: any;
  noteResult = [];
  totalCount = 0;
  submit = [];
  urlValue: boolean;
  progress: { percentage: number } = { percentage: 0 }
  disable= false;
  tooltip="";
  uploadTooltip="";

  copiUrl;
//   isScanner:boolean;

  config={
      dev: 'https://dpi-dev.cisco.com',
      stage:'https://dpi-stage.cisco.com',
      prod:'https://copi.cisco.com'
  }

  constructor(private search: SingleService,
    private data: DataService, 
    private urlvalue: GetURL, 
    private spin: SpinnerService, 
    private navigation: NavigateService,
    private sanitizer: DomSanitizer
    ) {
                this.v = []
                this.totalCount = 0;
                this.urlValue= urlvalue.getUrlValue();
                // this.isScanner = this.urlvalue.getScannerValue();
                if(location.origin.includes('-dev')){
                    this.copiUrl= this.config.dev;
                }else if(location.origin.includes('stage')){
                    this.copiUrl= this.config.stage;
                }else{
                    this.copiUrl= this.config.prod;
                }
            
  }



  ngOnInit() {
                if (this.flag == true) {
                    this.v = [];
                }
                for(let value of this.dropdown){
                    this.check(value); 
                } 
                this.data.FilterState.subscribe(message => {
                    this.check(message);
                });
                this.submit = [{
                    "cableType": [],
                    "dataRate": [],
                    "formFactor": [],
                    "reach": [],
                    "searchInput": this.searchInputList[0]["searchInput"],
                    "osType": [],
                    "transceiverProductFamily": [],
                    "transceiverProductID": [],
                    "networkDeviceProductFamily":[],
                    "networkDeviceProductID":[]
                }];
                this.page = 1;
                this.data.currentMessage.subscribe(message => {
                    this.message = message
                });
  }


  
  check(a){
            if (a != undefined) {
                var found = this.v.some(function(el) {
                    return el.id === a["id"] && el.name === a["name"];
                });
                if (!found) {
                    this.v.push(a);
                } else {
                    for (var i = 0; i < this.v.length; i++) {
                        if (this.v[i].id == a["id"] && this.v[i].name == a["name"]) {
                            this.v.splice(i, 1);
                            break;
                        }
                    }
                }
            }
    }



  newMessage() {
          this.data.changeMessage("Clear");
  }

  getProductImage(pid){
    this.imageError=null;
    this.productImage=null;
    this.spin.setdisplayValue(true);
    this.search.getCueImage(pid)
    .subscribe((res:any)=>{
        this.spin.setdisplayValue(false);
        console.log('got product image');
        this.productImage= this.sanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,'+res.image);
    },err=>{
        this.spin.setdisplayValue(false);
        this.imageError= err.error.message;
    })
  }


  clearAll() {
            this.v = [];
            this.submit = [{
                "cableType": [],
                        "dataRate": [],
                        "formFactor": [],
                        "reach": [],
                        "searchInput": this.searchInputList[0]["searchInput"],
                        "osType": [],
                        "transceiverProductFamily": [],
                        "transceiverProductID": [],
                        "networkDeviceProductFamily":[],
                        "networkDeviceProductID":[]
            }];
            this.navigation.emptyParams();
            this.navigation.navigateToURL();
  }



  saveFile(type: string) {
        this.spin.setdisplayValue(true);
        var urlResults ="";
        if(this.urlValue){
            urlResults = "/admin/api/networkdevice/search/export/excel";
            this.search.saveFile(this.searchInputList[0], urlResults, type).subscribe(
                (res) => {
                    if (type == 'excel') {
                        if(res.type == "application/json"){
                            this.search.messageExport(urlResults,this.searchInputList[0]).subscribe(res=>{
                                this.fileResponse = res.json();
                                this.show.emit(this.fileResponse);
                            })
                            this.spin.setdisplayValue(false);
                        }
                        else{
                            this.fileResponse = res;
                        this.show.emit(this.fileResponse);
                        saveAs(res, "TMGADM.xlsx"); //if you want to save it - you need file-saver for this : https://www.npmjs.com/package/file-saver
                        this.spin.setdisplayValue(false);
                    }
                    } 
                }
            );
        }
        else{
                urlResults = "/public/api/networkdevice/search/export/" + type;
                this.search.saveFile(this.searchInputList[0], urlResults, type).subscribe(
                    (res) => {
                        if (type == 'excel') {
                        this.fileResponse = res;
                        this.show.emit(this.fileResponse);
                            saveAs(res, "TMGCMXL.xlsx"); //if you want to save it - you need file-saver for this : https://www.npmjs.com/package/file-saver
                            this.spin.setdisplayValue(false);
                        
                        } else if (type == 'pdf') {
                            this.fileResponse = res;
                            this.show.emit(this.fileResponse);
                            saveAs(res, "TMGCMPDF.pdf"); //if you want to save it - you need file-saver for this : https://www.npmjs.com/package/file-saver
                            this.spin.setdisplayValue(false);
                        
                        } else if (type == 'csv') {
                            
                            this.fileResponse = res;
                            this.show.emit(this.fileResponse);
                            saveAs(res, "TMGCMCSV.csv"); //if you want to save it - you need file-saver for this : https://www.npmjs.com/package/file-saver
                            this.spin.setdisplayValue(false);
                        
                        }
                    }
                );
            }
  }




  showMessage(){
            this.show.emit(this.fileResponse);
            this.search.getResults(this.searchInputList[0]).subscribe(res=>{
                this.tableData=res;
                this.ResultsChanged.emit(this.tableData);
            })
            sessionStorage.clear();
  }


  getCOPIUrl(obj){   
    // if(this.isScanner){
    //     return this.sanitizer.bypassSecurityTrustUrl(this.copiUrl+'/scanner/?tpid='+obj.productModelId);
    //   }
      if(obj.show === 'N'){
          return this.sanitizer.bypassSecurityTrustUrl(obj.transceiverModelDataSheet);
      }
      return this.sanitizer.bypassSecurityTrustUrl(this.copiUrl+'/?tpid='+obj.productModelId);
  }

  getIOPUrl(obj){
    // if(this.isScanner){
    //     return this.sanitizer.bypassSecurityTrustUrl(location.origin+'/iop/scanner?tpid='+obj.productModelId);
    //   }
      if(obj.show === 'N'){
          return this.sanitizer.bypassSecurityTrustUrl(obj.transceiverModelDataSheet);
      }
      return this.sanitizer.bypassSecurityTrustUrl(location.origin+'/iop?tpid='+obj.productModelId);
  }


  chooseFile(){
         document.getElementById("uploadExcel").click();
   }
   


  selectFile(event){
            this.selectedFiles = event.target.files;
            this.fileName = this.selectedFiles[0].name;
  }



  showNotes(networkModelName, transceiverModelName,productModelId,networkModelId, versionId, releaseId,noteCount) {
                this.noteResult = [];
                this.notes = [networkModelName, transceiverModelName]
                this.search.getNotes({
                    "networkModelId": networkModelId,
                    "softwareReleaseId": releaseId,
                    "transceiverModelId": productModelId,
                    "transceiverVersionId": versionId
                }).subscribe(res => {
                    // this.noteResult = Object.values(res);
                    this.noteResult = Object.keys(res).map(itm => res[itm]);
                });
  }


  
  close(obj) {
            this.navigation.editFilters(obj,obj.filtername,false);
            this.navigation.navigateToURL();
  }


  
}