
<h6 class="filtersheading">Filters Selected</h6>
<div class="col-sm-12 rcoloumn">
    <div class="col-sm-2 left">Showing {{tableData.totalCount}} Network Device Product Family Results</div>
    <div *ngIf="!flag" class="smartfilter col-sm-8">
        <div class="right smart" *ngFor="let obj of v">{{obj.name}}<span class="close" (click)="close(obj)">X</span> </div>
    </div>

    <div align="right" class="col-sm-3 rights"><a href="javascript:void(0)" (click)="clearAll();newMessage()">CLEAR ALL FILTERS</a></div>
</div>


<div *ngIf="tableData.totalCount!=0" class=" col-sm-12 ">
    <div class="export">
        <div class="export-excel" title="{{tooltip}}">
            <button  id="exportExcel" type="button" class="btn btn--primary" (click)="saveFile('excel')" [disabled]="disable">
                <span class="icon-file-excel-o icon-small"></span> Export Excel
            </button>
        </div>
        <div *ngIf="!urlValue" class="export-pdf" title="{{tooltip}}">
            <button id="exportPdf" type="file" class="btn btn--primary" (click)="saveFile('pdf')" [disabled]="disable">
          <span class="icon-file-pdf-o icon-small"></span> Export PDF
        </button>
        </div>
        <div *ngIf="!urlValue" class="export-csv" title="{{tooltip}}">
            <button id="export-csv" type="button" class="btn btn--primary" (click)="saveFile('csv')" [disabled]="disable">
            <span class="icon-file-excel-o icon-small"></span> Export CSV
          </button>
        </div>
    </div>
</div>

<div class="row" *ngIf="tableData.totalCount!=0" >
    <div class="col">
        <b>Mouse over<span class="icon-notes icon-small"></span> icon to view notes for adapters (QSA / CVR-QSFP-SFP10G, QSA28 / CVR-QSFP28-SFP25G) and other important product information.</b><br>
        <span>Click on <b>Network Device Product ID</b> or <b>Transceiver Product ID</b> to be directed to data sheets</span>
    </div>
</div>


<div  class="page" *ngIf="tableData.totalCount!=0">
    <pagination-controls class="pagination" (pageChange)="page = $event"></pagination-controls>
</div>


<div *ngIf="tableData.totalCount==0">No Results Found</div>


<div class="results col-sm-12" *ngFor="let nd of tableData?.networkDevices |  paginate: { 
    itemsPerPage: 3,
    currentPage: page }">    
   <p headingTable class="tableHead" >{{nd.productFamily}}</p>
   <div class="table-responsive">

       <table class="table table--bordered table--wrap">
           <thead>
               <tr>
                   <th class="headings" colspan="2"></th>
                   <th class="headings" colspan="9" align="center">Transceiver Description</th>
                   <th class="headings" colspan="2" align="center">Software Release</th>
               </tr>
               <tr>
                   <th style="width:13%">Network Device Product ID</th>
                   <th style="width:13%">Transceiver Product ID</th>
                   <th style="width:7%">Data Rate</th>
                   <th style="width:5%">Form Factor</th>
                   <th style="width:5%">Max. Reach</th>
                   <th style="width:7%">Cable Type</th>
                   <th style="width:5%">Media</th>
                   <th style="width:7%">Connector Type</th>
                   <th style="width:7%">Transceiver Type</th>
                   <th style="width:8%">Case Temp</th>
                   <th style="width:7%">DOM HW Capable</th>
                   <th style="width:8%">Minimum</th>
                   <th style="width:8%">DOM SW</th>
               </tr>
           </thead>
           <tbody *ngFor="let obj2 of nd.networkAndTransceiverCompatibility;">
               <tr *ngFor="let obj3 of obj2.transceivers;let first = first; let last = last">
                   <td *ngIf="first" [attr.rowspan]=obj2.transceivers.length>
                       <a tooltip="Click to Network Device Datasheet" placement="top" [attr.href]="obj2.networkProductIdDataSheet" target="_blank">
                           {{obj2.productId || "NA"}}</a>
                       </td>
                   <td class="margin"> 
                        <a  *ngIf="obj3.noteCount" (mouseover)="showNotes(obj2.productId,obj3.productId,obj3.productModelId,obj3.networkModelId,obj3.versionId,obj3.releaseId,obj3.noteCount)">
                            <span  [tooltip]="popNotes" placement="right"  id={{obj3.productId}} 
                            class="icon-notes icon-small" containerClass="customClass">
                            </span>
                        </a>
                    
                        <a tooltip="Click to Product Cue Card" containerClass="cue" (click)="getProductImage(obj3.productId)" data-toggle="modal" data-target="#ProductImage"> <span class="badge text-bg-custom">CUE</span></a>
                        <a tooltip="Click to Cisco Optics Product Information Matrix or Datasheet" containerClass="copi" placement="top" [attr.href]="getCOPIUrl(obj3)" target="_blank">{{obj3.productId || "NA"}}</a>
                       <!-- change to getCopiURL(dpiId) -->
                       
                       <a tooltip="Click to Cisco Optics-to-Optics Interoperability Matrix or Datasheet" containerClass="iop" placement="top" [attr.href]="getIOPUrl(obj3)" target="_blank"><span class="icon-rtprx-rtptx-duplex" style="color: white;background-color: #6abf4b;"></span></a> 
                       <p class="endOfSale" *ngIf="obj3.endOfSale === 'Y'">End Of Sale</p>
                   </td>
                   <td>{{obj3.dataRate || "NA"}}</td>
                   <td>{{obj3.formFactor || "NA"}}</td>
                   <td>{{obj3.reach || "NA"}}</td>
                   <td>{{obj3.cableType || "NA"}}</td>
                   <td>{{obj3.media || "NA"}}</td>
                   <td>{{obj3.connectorType || "NA"}}</td>
                   <td>{{obj3.type || "NA"}}</td>
                   <td>{{obj3.temperatureRange || "NA"}}</td>
                   <td>{{obj3.digitalDiagnostic || "NA"}}</td>
                   <td>{{obj3.softReleaseMinVer || "NA"}}</td>
                   <td>{{obj3.softReleaseDOM || "NA"}}</td>
               </tr>
           </tbody>
       </table>
   </div>
</div>


<div  class="page" *ngIf="tableData.totalCount!=0">
    <pagination-controls class="pagination" (pageChange)="page = $event"></pagination-controls>

</div>


<ng-template #popNotes >
    <div class="notess" >
         <h3 >Notes  </h3><br>
                <div *ngIf="noteResult[0] != undefiened">
                    <h6 *ngIf="noteResult[0].length > 0">Network Device ({{notes[0]}})</h6>
                    <ul *ngIf="noteResult[0] != undefiened">
                        <ng-container *ngFor="let nd of noteResult[0]">
                            <li *ngIf="nd!=''">{{nd}}</li>
                        </ng-container>
                       
                    </ul>
                    </div>
                    <div *ngIf="noteResult[1] != undefiened">
                    <h6 *ngIf="noteResult[1].length >0">Transceiver Model ({{notes[1]}})</h6>
                    <ul *ngIf="noteResult[1] != undefiened"> 
                        <ng-container *ngFor="let tm of noteResult[1]">
                            <li *ngIf="tm!=''">{{tm}}</li>

                        </ng-container>
                       
                    </ul>
                    </div>
            </div>
                            
</ng-template>

<div class="modal" id="ProductImage" role="dialog">
    <div class="modal-dialog">
    
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
            <span></span>
            <h4 class="modal-title">Product Image</h4>
            <button style="margin: 0px;" type="button" class="close" data-dismiss="modal"><span aria-hidden="true" class="icon-close icon-medium"></span></button>
        </div>
        <div class="modal-body">
            <span *ngIf="imageError!=''">
                <p> {{imageError}} </p>
            </span>
             <span *ngIf="productImage">
                <img [src]="productImage" />
             </span>
            <!-- <img > -->
        </div>
      </div>
    </div>
</div>